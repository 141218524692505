export const imageSettingDefaults = {
  width: 300,
  height: 300,
  oversample: false
};

export const sidebarButtonDefaults = {
  icon: 'icon',
  onClick: () => {}
};
